.App {
  text-align: center;
  padding-top: 3em;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

nav.navbar{
  background-color: #EBF7EE;
}

a.nav-link {
  color: black;
  padding: 0;
  background-image: linear-gradient(to right, rgba(255,255,255,0) 50%, #ffffff 50%);
  background-position: -0% 0;
  background-size: 200% auto;
  transition: background-position 0.5s ease-out;
  border-left: #EBF7EE solid 2px;
}

a.nav-link:hover {
  background-position: -99.99% 0;
  border-left: rgb(157, 163, 157) solid 2px;
}

.ice-cream div.modal{
  background-color: none;
}

.vertical-alignment-helper {
  display:table;
  height: 100%;
  width: 100%;
  pointer-events:none;}
  
  .vertical-align-center {
  /* To center vertically */
  display: table-cell;
  vertical-align: middle;
  pointer-events:none;}
  


 div.modal-content{
  width:inherit;
  max-width:inherit; 
  height:inherit;
  margin: 0 auto;
  pointer-events:all;
  padding: 7em;
  border: none;
  background-color: #ffffff00;
  background-image: url(./icecream.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

.modal-backdrop {
    width: auto;
    opacity:0.5 !important;
}

div.modal-body {
  margin-top: 230px;
  margin-left: 25px;
  margin-right: 12px;
  font-family: Raleway;
  font-weight: 500;
  text-align: center;
}

div.fade.in.modal {
  display:flex !important;
}

.modal-dialog {
  margin: auto;
}

.ice-cream-close{
  background-color: #b0cea5;
  border-color: #c3e0ad;
  color: #325027;
  border-radius: 40%;
}

.ice-cream-close:hover{
  background-color: #8dac82;
  border-color: #c3e0ad;
  color: #203617;
  border-width: 2px;
}

.ice-cream-close:clicked{
  background-color: #8dac82;
  border-color: #c3e0ad;
  border-width: 2px;
}

.bi-envelope{
  color: black;
}

.bi-envelope:hover{
  color: #d1e6c9;
}

.typing{ 
  /* background-color: rgba(255, 255, 255, 0.288); */
  margin-bottom: 0;
  font-family: Raleway;
  font-size: 10vw;
  border-right: solid 3px black;
  white-space: nowrap;
  overflow: hidden;  
  animation: animated-text 4s steps(29,end) 1s 1 normal both,
             animated-cursor 600ms steps(29,end) infinite;
}

.socials{
  text-align: left;
  /* padding-left: 5px; */
}

.socials-icons{
  font-size: 7vw;
  padding: 14px;
}

.socials-icons:hover{
  color: white;
  background-color: #212529;
  border-radius: 50%;
  padding: 14px;
}

@keyframes animated-text{
  from{width: 0;}
  to{width: 71%;}
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

img.chaya-greisman-pic{
  width: 21em;
}

.about-text{
  line-height: 1.6;
  font-size: 2vw;
  margin: 2em;
  margin-right: 2em;
  margin-left: 2em;
}

.project-image{
  margin: 5px;
  margin-bottom: 30px;
}

div.project-card{
  margin-left: '20px';
  margin-right: '20px';
  padding: '20px';
  background-color: "white";
  border-radius: '15px';
}

.project-content{
  text-align: center;
}

.project-content > *{
  margin: 10px;
}

h1.project-title{
  font-family: 'Raleway';
  font-weight: '600';
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.contact{
  text-align: left;

}

.contact-item{
  display: flex;
}

.contact-item:hover{
  color: white;
  background-color: #212529;
}

.contact-item h1{
  margin: auto;
  margin-left: 5px;
  font-family: 'Raleway';
  font-weight: 600;
  font-size: 6vw;
}

.contact-icons{
  font-size: 8vw;
  padding: 14px;
}


.close-project{
  text-align: right;
  font-size: 5vw;
}

.resume{
width: 100%;
}

.appearing-text-container{
  text-align: left;
}

h3.appearing-text::before {
  content: ' // software engineer // knowledge junkie // designer + artist // self taught gymnast // ice cream enthusiast //  ';
  font-family: 'Raleway';
  font-weight: 600;
  background-color: honeydew;
  padding-left: 14px;
  font-size: 21px;
  text-align: center;
  animation: fadein 10s;
  -moz-animation: fadein 10s; /* Firefox */
  -webkit-animation: fadein 10s; /* Safari and Chrome */
  -o-animation: fadein 10s; /* Opera */

}

.me-pic{
  display: block;
  margin-left: 1%;
  border-radius: 50%;
  animation: fadein 10s;
  -moz-animation: fadein 10s; /* Firefox */
  -webkit-animation: fadein 10s; /* Safari and Chrome */
  -o-animation: fadein 10s; /* Opera */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media (min-width: 300px){
  .home-content{
    margin-top: 35%;
  }
  .me-pic{
    width: 10em;
    border: solid #212529 3px;
  }
}

@media (min-width: 1000px){
  .home-content{
    margin-top: 5%;
  }
  .me-pic{
    width: 18em;
    border: solid #212529 5px;
  }
}

.home-content{

}

@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
      opacity:0;
  }
  to {
      opacity: 1;
  }
}